import React, { useState, useEffect, Fragment } from "react"
import { ProFirst, Layout, SEO, ProBuy, RedBuyBtn } from '@/components/index'
import { useIntl } from "react-intl"
import Axios from "axios";
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import { getIp } from '@/services/request'

import {
  Intro,
  Detail,
  JPSwiperBanner,
  JPUse,
  JPSwiperView,
  JPIntro,
  JPNotes,
  Update
} from '@/components/Adapter/index'
import {
  proBg_ada_pc,
  proBg_ada_mobile,
  pro_ada_logo, adaBuy, adaBuy_mobile
} from '@/images/ada/index'
import { useGTMInit} from '@/hooks/useGTM.js'


const Index = () => {
  const intl = useIntl()
  const [country, setCountry] = useState('');
  const region = getCountry();




  const checkIfRender = () => {
    getIp().then(res => {
      const { code, data } = res?.data
      if (code == 2000) {
        const countryCode = data.country
        setCountry(countryCode);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    checkIfRender()
    useGTMInit('products','Adapter')
  }, [])

  const proFirstMsg = {
    textBlack: true,
    desc: intl.formatMessage({ id: "ada.banner.t" }),
    logo: pro_ada_logo,
    backgroundColor: "#F2F2F2",
    pcImg: proBg_ada_pc,
    mobileImg: proBg_ada_mobile,
    title: ""
  }
  const buyIntro = {
    img: adaBuy,
    mobileImg: adaBuy_mobile,
    name: "XREAL Adapter",
    cate: "Adapter",
    shop_link: buy_link[region]['adapter'].shop,
    amazon_link: buy_link[region]['adapter'].amazon,
    amazon_sale: '',
  }
  return (<Layout showMenu showsubmenu={true} menuBlack={true}>
    <SEO page="Adapter" />
    <RedBuyBtn cate='adapter' gaName='Adapter' />

    <ProFirst msg={proFirstMsg} />
    <Update />
    {region === 'jp' || country === 'JP' ?
      <Fragment>

        <JPSwiperBanner />
        <JPUse />
        <JPSwiperView />
        <JPIntro />
        <JPNotes />
        <div style={{ height: '120px' }}></div>
      </Fragment>
      : <Fragment>
        <Intro />
        <Detail />
      </Fragment>}
    <ProBuy pro_intro={buyIntro} />

  </Layout>


  )
}

export default Index
